import React from 'react';
import './App.css';


class Factoid extends React.Component { 
  constructor(props) {
     super(props);
     let storedSelection = JSON.parse(localStorage.getItem(('factoid' + this.props.id)));     
     this.state = { selected: storedSelection };
  }

  changeSelected() { 
    let newSelected = !this.state.selected;
    this.setState({selected: newSelected});
    localStorage.setItem(('factoid' + this.props.id), newSelected);
  }

  render() {
    let classNameForFactoid = this.state.selected ? 'col selected' : 'col unselected';
    let selectedCallback = this.changeSelected.bind(this);
    return (
      <div className={classNameForFactoid} key={classNameForFactoid} onClick={selectedCallback}>
        <div className="inner">{this.props.description}</div>
      </div>
    );
  }
}

class App extends React.Component {
  shuffleArray(arr) { 
    let array = arr;

    for(let i = array.length  - 1; i > 0; i--){
      const j = Math.floor(Math.random() * i)
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }

    return array;
  }

  randomNewGame() { 
    let gameFactoids = [
            {
              description: '"Here for the right reasons"',
              id: 1,
            },
            {
              description: 'Cheers',
              id: 2,
            },
            {
              description: '"Can I steal you for a second"',
              id: 2,
            },
            {
              description: '"Luckiest guy in the world"',
              id: 3,
            },
            {
              description: 'Someone cries',
              id: 4,
            },
            {
              description: '"I hoped it was you"',
              id: 5,
            },
            {
              description: 'Windmill joke',
              id: 6,
            },
            {
              description: 'Hannah B & Jed discussed',
              id: 7,
            },
            {
              description: '"Most dramatic season ever"',
              id: 8,
            },
            {
              description: 'Peter\'s first kiss',
              id: 10,
            },
            {
              description: '"from a small town"',
              id: 11,
            },
            {
              description: 'Someone wears a costume',
              id: 12,
            },
            {
              description: 'Influencer / model',
              id: 13,
            },
            {
              description: 'Trainer / dancer',
              id: 14,
            },
            {
              description: 'Someone wears a white dress',
              id: 15,
            },
            {
              description: 'A drink is spilled',
              id: 16,
            },
            {
              description: 'Someone curses (bleeped out)',
              id: 17,
            },
            {
              description: 'Someone leaves early / sent home',
              id: 18,
            },
            {
              description: 'Someone calls him "Pilot Pete"',
              id: 19,
            },
            {
              description: 'A date involving a plane',
              id: 20,
            },
            {
              description: 'A medical or dental professional',
              id: 21,
            },
            {
              description: 'Someone talks about a pet',
              id: 22,
            },
            {
              description: '"I am so nervous"',
              id: 23,
            },
            {
              description: 'A girl named Sarah',
              id: 24,
            },
            {
              description: 'Talks about religion',
              id: 25,
            },
            {
              description: 'A pagent girl',
              id: 26,
            },
            {
              description: 'Cats vs Rats is hinted at',
              id: 27,
            },
            {
              description: 'From California',
              id: 28,
            },
            {
              description: '"Can I walk you out?"',
              id: 29,
            },
            {
              description: 'Talks about her ex',
              id: 30,
            },
            {
              description: 'Plays guitar, sings, or reads poetry',
              id: 31,
            },
            {
              description: '2+ flight attendants',
              id: 32,
            },
            {
              description: 'Peter\'s parents',
              id: 33,
            },
            {
              description: 'Girl in their 30s',
              id: 34,
            },
            {
              description: 'Hannah B and Peter talk',
              id: 35,
            },
            {
              description: '"Really close to my family"',
              id: 36,
            },
    ];

    let shuffledFactoids = this.shuffleArray(gameFactoids);

    return(
      {
        game: {
          gameType: 'bach_episode_1',
          gameName: 'Bachelor Premiere Episode',
          gameFactoids: shuffledFactoids,
          random: Math.random(),
        }
      }
    );
  }

  constructor(props) {
     super(props);
     let fetchedGame = localStorage.getItem('bachelor_bingo_game');
     if (!fetchedGame) { 
      this.state = this.randomNewGame();
      localStorage.setItem('bachelor_bingo_game', JSON.stringify(this.state));
     } else { 
      this.state = JSON.parse(fetchedGame);
     }
   }

  factoidsForRows(factoids) { 
    let factoids_split_by_row = [];
    for(var i = 0; i <= factoids.length - 6; i += 6) { 
      factoids_split_by_row.push(factoids.slice(i, i + 6));
    }

    return factoids_split_by_row;
  }

  refresh() { 
    return this.state.game.refresh;
  }

  renderGrid(factoids) { 
    var factoids_split_by_row = this.factoidsForRows(factoids); 
    return (
        <div>
          <div className="grid">
            {
              factoids_split_by_row.map(function(factoids_for_row, row_index) { 
                var unique_row_key = row_index.toString();
                return (<>
                  { 
                    factoids_for_row.map(
                      function(factoid, col_index) {
                        var unique_cell_key = row_index.toString() + col_index.toString();
                        return (
                          <Factoid id= { factoid.id } key={unique_cell_key} description={factoid.description}/> 
                        );
                      }
                    )
                  }
                </>);
              })
            }
          </div>
        </div>
    );
  }

  clearGame() { 
    let newGame = this.randomNewGame();
    localStorage.setItem('bachelor_bingo_game', JSON.stringify(newGame));
    this.setState(newGame);
    for(var i = 0; i <= 36; i++) { 
      localStorage.setItem(('factoid' + i), false);
    };
  }

  render() {
    let clearGame = this.clearGame.bind(this);

    return (
      <div className="App" key={ this.state.game.random}>
        <header className="App-header">
          <h1>Bach Bingo</h1>
          <button onClick={clearGame}>Clear</button>
        </header>

        { this.renderGrid(this.state.game.gameFactoids) }
      </div>
    );
  }
}

export default App;
